import axios from 'axios';
import debugLog from '@/aux/debugLog';

const FRONTEND_VERSION = '0.1.075';

export const SERVER_DATA = {
  API:
    process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : '/api',
  RESPONSE: {
    SUCCESS: 200,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    SERVER_ERROR: 500,
  },
  PUBLIC_FOLDER:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8000/public'
      : '/public',
  DEFECT_IMAGES:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8000/images'
      : '/public/defect_images',
};

// Configure axios connection
const axiosConfig = axios.create({
  baseURL: SERVER_DATA.API,
});

// _memoize boolean: mem-function (to memoize) only works if the function has
// an argument
export const getFrontendConfig = async (
  _memoize: boolean
): Promise<boolean> => {
  debugLog('Loading configuration from backend');

  try {
    const axiosResponse = JSON.stringify(
      (await axiosConfig.get(`/get-config`)).data
    );
    debugLog('Configuration from backend loaded');

    localStorage.setItem('frontendConfig', axiosResponse);
  } catch (error) {
    debugLog('Unable to load configuration from backend');
  }

  return true;
};

export const APP_SETTINGS = (
  setting: string
): string | number | boolean | undefined => {
  if (
    !localStorage.getItem('frontendConfig') ||
    JSON.parse(localStorage.getItem('frontendConfig') as string)[setting] ===
      undefined
  ) {
    return DEFAULT_APP_SETTINGS.get(setting);
  }

  const appSetting = JSON.parse(
    localStorage.getItem('frontendConfig') as string
  )[setting];

  /*
  appSetting =
    setting === 'FOOTER_TEXT'
      ? `${appSetting} (v${FRONTEND_VERSION})`
      : appSetting;
  */

  return appSetting;
};

const DEFAULT_APP_SETTINGS = new Map<string, string | number | boolean>([
  ['APP_TITLE', 'Trouble Report Tool'],
  ['FRONTEND_VERSION', FRONTEND_VERSION],
  ['ATO_NAME', 'ATO Name'],
  ['ATO_LOGO', 'interface/ATO_logo.png'],
  ['NAV_HEADER_BG_COLOR', 'grey'],
  ['NAV_HEADER_TEXT_COLOR', 'white'],
  ['REFRESH_CONTENT_INTERVAL_IN_MIN', 1.0],
  ['SHOW_DATA_EXPIRED_WARNING_IN_MIN', 15.0],
  ['TIME_LOCALE', 'en-gb'],
  ['BETA_VERSION', 'true'],
  ['FOOTER_TEXT', 'Trouble Report Tool'],
  ['MAX_UPLOAD_FILE_SIZE_IN_MB', 5.0],
  ['MAX_UPLOAD_IMAGE_DIMENSION_IN_PX', 1600],
  ['UPLOAD_IMAGE_FORMAT', 'jpeg'],
  ['UPLOAD_IMAGE_QUALITY', 0.7],
  ['DEBUG', true],
  ['APP_CONFIGURATION_CACHE_TIME', 1.0 * 60.0 * 60.0 * 1000], // hour to milli-sec
  ['BYPASS_USER_TERMS', false],
  ['MAX_DEFERRED_DEFECTS', 3],
  ['LIMIT_DEFERRED_DEFECTS', false],
  ['PRIVACY_POLICY', '_.pdf'],
]);

export const needToDownloadTerms = async (): Promise<{
  showTerms: boolean;
  termsActualVersion: number;
}> => {
  // Get the version of the current terms from the server (as a string)
  const termsVersionString: string = (
    await axiosConfig.get(`/get-terms/version`)
  ).data.version;

  // Convert the version of the current terms to a number
  const termsVersion: number = !isNaN(Number(termsVersionString))
    ? Number(termsVersionString)
    : 1.0;

  // Get the version of the currently accepted terms (as a string)
  const acceptedTermsVersionString: string | null = localStorage.getItem(
    'TroubleReportToolAcceptedTermsVersion'
  );

  // Conver the version of the currently accepted terms to a number
  const acceptedTermsVersion: number =
    !isNaN(Number(acceptedTermsVersionString)) && acceptedTermsVersionString
      ? Number(acceptedTermsVersionString)
      : -1.0;

  if (acceptedTermsVersion < termsVersion)
    return { showTerms: true, termsActualVersion: termsVersion };

  return { showTerms: false, termsActualVersion: termsVersion };
};

export const getTerms = async (): Promise<string> => {
  return (await axiosConfig.get(`/get-terms`)).data;
};
